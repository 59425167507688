var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark","variant":"info"}},[_c('b-navbar-brand',[_vm._v("Admin")]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',[_c('router-link',{class:[
              _vm.currentRoute.includes('/admin/dashboard/')
                ? 'link-active'
                : 'link-normal' ],attrs:{"to":"/admin/dashboard/allsites"}},[_vm._v("Dashboard")])],1),_c('b-nav-item',[_c('router-link',{class:[
              _vm.currentRoute.includes('datasets')
                ? 'link-active'
                : 'link-normal' ],attrs:{"to":"/admin/datasets"}},[_vm._v("Datasets")])],1),_c('b-nav-item',[_c('router-link',{class:[
              _vm.currentRoute.includes('users') ? 'link-active' : 'link-normal' ],attrs:{"to":"/admin/users"}},[_vm._v("Users")])],1),_c('b-nav-item',[_c('router-link',{class:[
              _vm.currentRoute.includes('downloads') ? 'link-active' : 'link-normal' ],attrs:{"to":"/admin/downloads"}},[_vm._v("Downloads")])],1),_c('b-nav-item',[_c('b-icon',{staticClass:"link-normal",attrs:{"icon":"power","aria-hidden":"true"}}),_c('a',{staticClass:"link-normal",on:{"click":_vm.handleLogout}},[_vm._v("Logout")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }